// src/components/Formulario.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { crearEntradaDictionary, editarEntradaDictionary } from '../api/dictionaryAPI';

const Formulario = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const entryToEdit = location.state?.entry;

  const [form, setForm] = useState({
    keyword: '',
    description: '',
    context: '',
  });

  const [error, setError] = useState('');

  useEffect(() => {
    if (entryToEdit) {
      setForm({
        keyword: entryToEdit.Keyword || '',
        description: entryToEdit.Description || '',
        context: entryToEdit.Context || '',
      });
    }
  }, [entryToEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validar campos
    if (!form.keyword.trim() || !form.description.trim() || !form.context.trim()) {
      setError('Todos los campos son obligatorios.');
      return;
    }

    try {
      if (entryToEdit) {
        // Editar entrada
        await editarEntradaDictionary(entryToEdit.ID, {
          description: form.description,
          context: form.context,
        });
        alert('Entrada editada con éxito');
      } else {
        // Crear nueva entrada
        await crearEntradaDictionary(form);
        alert('Entrada añadida con éxito');
      }
      navigate('/diccionario'); // Redirigir a la vista de "Diccionario"
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h1>{entryToEdit ? 'Editar Entrada' : 'Crear Nueva Entrada'}</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Keyword:
            <input
              type="text"
              name="keyword"
              value={form.keyword}
              onChange={handleChange}
              required
              readOnly={!!entryToEdit} // Bloquear campo si es para editar
            />
          </label>
        </div>
        <div>
          <label>
            Descripción:
            <input
              type="text"
              name="description"
              value={form.description}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Contexto:
            <textarea
              name="context"
              value={form.context}
              onChange={handleChange}
              required
            ></textarea>
          </label>
        </div>
        <div style={{ marginTop: '10px' }}>
          <button type="submit">{entryToEdit ? 'Guardar Cambios' : 'Añadir'}</button>
          <button
            type="button"
            onClick={() => navigate('/diccionario')}
            style={{ marginLeft: '10px' }}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};

export default Formulario;
