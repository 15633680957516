// Obtener los datos del diccionario GET

export const obtenerEntradasDictionary = async () => {
    try {
      const response = await fetch('https://makoservicio.vulcanics.mx/api/dictionary', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.statusText}`);
      }
  
      const result = await response.json();
  
      // Ajustar para casos en los que los datos están en un campo "data"
      if (!result || !result.data || !result.data.length) {
        throw new Error('No se encontraron entradas en el diccionario.');
      }
  
      return result.data; // Retornamos las entradas que están en "data"
    } catch (error) {
      console.error('Error al obtener entradas del diccionario:', error.message);
      throw error;
    }
  };

  // Añadir datos POST

export const crearEntradaDictionary = async (entrada) => {
    try {
      const response = await fetch('https://makoservicio.vulcanics.mx/api/dictionary', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(entrada), // Enviar datos en formato JSON
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud POST: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (!data.success) {
        throw new Error(data.message || 'Error al crear la entrada.');
      }
  
      console.log('Entrada creada con éxito:', data);
      return data; // Devolver la respuesta de la API
    } catch (error) {
      console.error('Error al crear la entrada en el diccionario:', error.message);
      throw error;
    }
  };


// Editar el dato PUT

export const editarEntradaDictionary = async (id, updatedFields) => {
    try {
      const response = await fetch(`https://makoservicio.vulcanics.mx/api/dictionary?id=${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedFields),
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud PUT: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (!data.success) {
        throw new Error(data.message || 'Error al editar la entrada.');
      }
  
      console.log(`Entrada con ID ${id} editada con éxito.`);
      return data;
    } catch (error) {
      console.error('Error al editar la entrada:', error.message);
      throw error;
    }
  };
  


// Eliminar dato DELETE

export const eliminarEntradaDictionary = async (id) => {
    try {
      const response = await fetch(`https://makoservicio.vulcanics.mx/api/dictionary?id=${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error en la solicitud DELETE: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      if (!data.success) {
        throw new Error(data.message || 'Error al eliminar la entrada.');
      }
  
      console.log(`Entrada con ID ${id} eliminada con éxito.`);
      return data;
    } catch (error) {
      console.error('Error al eliminar la entrada:', error.message);
      throw error;
    }
  };
  
  
  