// src/components/Diccionario.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { obtenerEntradasDictionary, eliminarEntradaDictionary } from '../api/dictionaryAPI';

const Diccionario = () => {
  const navigate = useNavigate();
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({ id: '', keyword: '' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await obtenerEntradasDictionary();
        setEntries(data);
        setFilteredEntries(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  // Manejar cambios en los filtros
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });

    let filtered = entries;

    if (name === 'id' && value.trim()) {
      filtered = filtered.filter((entry) =>
        entry.ID.toString().includes(value.trim())
      );
    }

    if (name === 'keyword' && value.trim()) {
      filtered = filtered.filter((entry) =>
        entry.Keyword.toLowerCase().includes(value.trim().toLowerCase())
      );
    }

    setFilteredEntries(filtered);
  };

  // Función para manejar la edición
  const handleEdit = (entry) => {
    navigate('/formulario', { state: { entry } });
  };

  // Función para manejar la eliminación
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(`¿Estás seguro de eliminar la entrada con ID ${id}?`);
    if (confirmDelete) {
      try {
        await eliminarEntradaDictionary(id);
        setEntries((prevEntries) => prevEntries.filter((entry) => entry.ID !== id));
        setFilteredEntries((prevEntries) => prevEntries.filter((entry) => entry.ID !== id));
        alert(`Entrada con ID ${id} eliminada con éxito.`);
      } catch (error) {
        alert(`Error al eliminar la entrada: ${error.message}`);
      }
    }
  };

  return (
    <div>
      <h1>Vista de Diccionario</h1>
      <button onClick={() => navigate('/inicio')}>Volver</button>
      <button onClick={() => navigate('/formulario')} style={{ marginLeft: '10px' }}>
        Crear Nueva Entrada
      </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Filtros */}
      <div style={{ marginTop: '20px' }}>
        <label>
          Buscar por ID:
          <input
            type="text"
            name="id"
            value={filters.id}
            onChange={handleFilterChange}
            placeholder="Escribe el ID"
            style={{ marginLeft: '10px', marginRight: '20px' }}
          />
        </label>
        <label>
          Buscar por Keyword:
          <input
            type="text"
            name="keyword"
            value={filters.keyword}
            onChange={handleFilterChange}
            placeholder="Escribe el keyword"
            style={{ marginLeft: '10px' }}
          />
        </label>
      </div>

      {/* Tabla */}
      <table border="1" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Keyword</th>
            <th>Descripción</th>
            <th>Contexto</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {filteredEntries.map((entry) => (
            <tr key={entry.ID}>
              <td>{entry.ID}</td>
              <td>{entry.Keyword}</td>
              <td>{entry.Description}</td>
              <td>{entry.Context}</td>
              <td>
                <button onClick={() => handleEdit(entry)} style={{ marginRight: '10px' }}>
                  Editar
                </button>
                <button onClick={() => handleDelete(entry.ID)} style={{ color: 'red' }}>
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Diccionario;
